import React from 'react';
import Grid from '@mui/material/Grid2'
import { Box,Slide,Grow,Link,Button } from '@mui/material';
import { WideContainer,NarrowContainer } from 'components/Base/MUI/containers/PxftContainer';
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './generalColumn.css'

const GeneralColumn=({rowData={},containerStyle={},reverse=false})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const containerRef = React.useRef(null);

    const Content = () => {
        return(
                <Grid size={{xs:12,md:6}} flexDirection='column' className='center-align'>
                    <Slide timeout={{enter:800}} in={inView} direction={reverse?"left":"right"} container={containerRef.current}>
                    <Box p={2} maxWidth='400px'>
                        <Box py={{xs:1,lg:3}} textAlign={isMobile? "center":''}>
                            <p className='subtitle'>{rowData?.subtitle}</p>
                            <h1>{rowData?.title}</h1>
                        </Box>
                        <Box py={{xs:1,lg:2}} textAlign={isMobile? "center":''}>
                            {
                                rowData?.contents &&
                                rowData?.contents.map((content, i)=>(
                                    <p key={i}>{content}</p>
                                ))
                            }
                        </Box>
                    </Box>
                    </Slide>
                </Grid>

        )
    }
    const Image = () => {
        return(
            <Grid size={{xs:12,md:6}} className='center-align'>
                <Grow timeout={{enter:1200}} in={inView}>
                    {rowData?.videoSrc?
                    <div className='video-container center-align'>
                        {isMobile ? <iframe width="332" height="249" src={rowData?.videoSrc} title={rowData?.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            : <iframe width="576" height="432" src={rowData?.videoSrc} title={rowData?.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                        </div>
                    :
                    <img loading='lazy' className='contentPic' src={rowData?.imgSrc}
                    srcSet={`${rowData?.imgSrc} 1x, ${rowData?.imgSrc2x} 2x`}
                    alt=''/>
                    }
                </Grow>
            </Grid>

        )
    }

    if(isMobile){
        return(
            <WideContainer className='basic-column'>
                <Grid className={`${containerStyle} center-align`} px={2} py={2} mt={3} ref={containerRef}>
                <Grid className='center-align' sx={{flexDirection:'column',alignItems:'center',width:'100%', maxWidth:'var(--adinify-max-width)'}}>
                    <Content/>
                    <Image/>
                    <div className='observer' ref={inViewRef}></div>
                    </Grid>
                </Grid>

            </WideContainer>
        )
    }
    return (
        <WideContainer className='basic-column'>
            <Grid className={`${containerStyle} center-align`} px={2} mt={3} py={2} width='100%' ref={containerRef}>
                <Grid className='center-align' sx={{alignItems:'center',width:'100%', maxWidth:'var(--adinify-max-width)'}}>
                { !reverse && 
                <Grid container width='100%' sx={{width:'100%'}}>
                    <Content/>
                    <Image/>
                </Grid>}
                {reverse && 
                <Grid container width='100%'>
                    <Image/>
                    <Content/>
                </Grid>}
                <div className='observer' ref={inViewRef}></div>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default GeneralColumn;