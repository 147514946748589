import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from 'views/Home/Home';
import Layout from 'views/Base/Layout';
import ContactUs from 'views/ContactUs/ContactUs';
import SignUp from 'views/SignUp/SignUp';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home />}/>
          <Route path="/sign-up" element={<SignUp />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

