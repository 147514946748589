export default function LogoSvg(){
    return (
        <svg width="148" height="40" viewBox="0 0 148 40">
        <defs>
            <clipPath>
            <rect width="39.93" height="34.74" fill="none"/>
            </clipPath>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#22ddd5"/>
            <stop offset="1" stopColor="#1a73e8"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#054daa"/>
            <stop offset="1" stopColor="#1a73e8"/>
            </linearGradient>
            <linearGradient id="linear-gradient-3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#054daa"/>
            <stop offset="1" stopColor="#063067"/>
            </linearGradient>
        </defs>
        <g transform="translate(-396 -250.86)">
            <text transform="translate(446 282)" fill="#171717" fontSize="32" fontFamily="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Adinify</tspan></text>
            <g transform="translate(392 283.685)">
            <g transform="translate(4 -32.825)">
                <g transform="translate(0 2.56)" clipPath="url(#clip-path)">
                <path d="M65.89,34h0a5.125,5.125,0,0,1-7.023-1.715L43.925,7.733a5.108,5.108,0,0,1,8.746-5.28L67.612,27.006A5.081,5.081,0,0,1,65.89,34" transform="translate(-28.423 0)" fill="url(#linear-gradient)"/>
                <path d="M2.367,45.823h0a4.7,4.7,0,0,1-1.733-6.44L12.854,18.3l5.491,9.384L8.833,44.1a4.744,4.744,0,0,1-6.466,1.726" transform="translate(0 -12.072)" fill="url(#linear-gradient-2)"/>
                <path d="M49.518,77.7,44.14,69.632l4.828-.052a6.208,6.208,0,0,1,5.223,2.44l4.723,7.892L54.1,79.829c-2.648.048-3.7-.673-4.585-2.126" transform="translate(-29.053 -45.892)" fill="url(#linear-gradient-3)"/>
                </g>
                <rect width="40" height="3" transform="translate(0 37)" fill="none"/>
                <path d="M0-1l39.93.078v2.5H0Z" transform="translate(0 1)" fill="none"/>
            </g>
            </g>
        </g>
        </svg>

    )

}