import React from 'react';
import Grid from '@mui/material/Grid2'
import SignUpForm from './SignUpForm';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import GeneralTitle from 'components/GeneralTitle/GeneralTitle';

const SignUp = () => {
    const row = {
        title:'Sign up your information',
        contents:[]
    }
    return (
        <Grid container className='basic-page-container center-align'>
            <GeneralTitle rowData={row}/>
            <Grid size={{md:9,xs:12}}  sx={{
                background:'rgba(255,255,255,0.1)',
                borderRadius:"15px"}} className='center-align'>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_API_URL}>
                    <SignUpForm/>
                </GoogleReCaptchaProvider>
            </Grid>
        </Grid>
    );
}

export default SignUp;