import React from 'react';
import Grid from '@mui/material/Grid2'

const HomeHeader=({rowData})=> {
    return (
        <Grid className='background-video-container'>
            <video autoPlay muted loop className="background-video">
                <source src="https://cdn.pixfuture.com/video/853870-hd_1920_1080_25fps.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
            <Grid className='content'>
                <h1>{rowData?.title}</h1>
                {
                    rowData?.contents && 
                    rowData?.contents.map((content,i)=>(
                        <p key={i}>{content}</p>
                    ))
                }

            </Grid>
        </Grid>
    );
}

export default HomeHeader;