import React from 'react';
import { NavLink, Link, } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MobileMenu from './MobileMenu';
import naviData from 'data/navi/naviLinkItem.json';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const NavigationContents = ({darkTheme}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!naviData || isMobile) return null;

  return (
    <nav className="site-main-menu">
      <ul>
        {naviData.map((item, i) => {
        //   if ((item.list.length > 1 || (item.list.length === 1 && item.title !== item.list[0].text))) {
            return (
              <li key={`nav-${item.id}-${i}`} className="has-children">
                <NavLink to="#">
                  <Typography className="menu-text" color={darkTheme?'#fff':'' }>
                    {item.title}
                    <KeyboardArrowDownIcon
                      fontSize=""
                      sx={{ pl: 0.3, mt: 0.3 }}
                    />
                  </Typography>
                </NavLink>
                <ul className="sub-menu">
                  {item.list.map((listItem, i) => (
                    <li key={`subnav-${listItem.id}-${i}`}>
                      {
                        <Link
                        to={listItem.url}
                        target={listItem.outSource ? '_blank' : ''}
                        rel="noopener noreferrer"
                      >
                        {listItem.text}
                      </Link>
                      }

                    </li>
                  ))}
                </ul>
              </li>
            )
          }

        //   return (
        //     <li key={i}>
        //       {
        //       <Link
        //         to={item.list[0].url}
        //         target={item.list[0].outSource ? '_blank' : ''}
        //         rel="noopener noreferrer"
        //       >
        //         {item.title}
        //       </Link>
        //   }
        //     </li>
        //   )
        // }
        )}
      </ul>
    </nav>
  )
}

export default NavigationContents;