import React from 'react';
import { WideContainer } from 'components/Base/MUI/containers/PxftContainer';
import Grid from '@mui/material/Grid2'
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
      <WideContainer className="ynt-footer-container">
      <Grid container sx={{pb:2,pt:1,px:'40px'}} width='100%' className='center-align'>
          <Grid size={{xs:12,md:6}}>
              <p className='copyright'>&copy; {year} Adinify. All Rights Reserved. </p>
          </Grid>
          {/* <Grid item xs={12} md={2.5} className='footer-links-inline center-align' sx={{justifyContent:'center'}}>
              <Link to='/terms-and-conditions'>Terms of Service</Link>
              <Link to='/terms-and-conditions#privacy-policy'>Privacy Policy</Link>
          </Grid> */}
          <Grid size={{xs:12,md:3.5}} className='footer-social-inline' sx={{justifyContent:{xs:'center',md:'end'}, paddingRight: {xs: 0, md: '45px'}}}>
              <a href="/" target="_blank" rel="noopener noreferrer"><IconButton><InstagramIcon fontSize='12px'/></IconButton></a>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <IconButton style={{ color: 'rgba(0,0,0, .6)' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 50 50" style={{margin: '1px 0 0 1px'}}>
                    <path fill="rgba(0, 0, 0, 0.54)" d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                  </svg>
                </IconButton>
              </a>
              <a href="/" target="_blank" rel="noopener noreferrer"><IconButton ><LinkedInIcon fontSize='12px'/></IconButton></a>
              <a href="/" target="_blank" rel="noopener noreferrer"><IconButton ><YouTubeIcon fontSize='12px'/></IconButton></a>
          </Grid>
      </Grid>
      </WideContainer>
  )
}

export default Footer;